import { domen } from "./consts";
const id = localStorage.id;

//Получение списка заказов
export async function getOrders() {
  let form = new FormData();
  form.append("id", id);
  const response = await fetch(domen + "/getOrders.php", {
    method: "POST",
    body: form,
  });

  return await response.json();
}

//Получение деталей заказа
export async function getOrder(idT) {
  let form = new FormData();
  form.append("id", id);
  form.append("idT", idT);
  const response = await fetch(domen + "/getOrder.php", {
    method: "POST",
    body: form,
  });

  return await response.json();
}

//Форматирование даты
export const formatDate = (date) => {
  let d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  let year = d.getFullYear();
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  return [year, month, day].join("-");
};

//Изменить статус заказа

export async function updateStatus(idOrder, status) {
  let form = new FormData();
  form.append("id", idOrder);
  form.append("status", status);
  const response = await fetch(domen + "/cardsApi/updateOrder.php", {
    method: "POST",
    body: form,
  });
}

//Получение информации о карточке товара

export async function getCard(idCard) {
  let form = new FormData();
  form.append("ID", idCard);
  const response = await fetch(domen + "/getCard.php", {
    method: "POST",
    body: form,
  });

  return await response.json();
}

//Получение заказов пользователя

export async function getOrdersUser(tel) {
  let form = new FormData();
  form.append("tel", tel);
  form.append("id", id);
  const response = await fetch(domen + "/getOrdersByTel.php", {
    method: "POST",
    body: form,
  });

  return await response.json();
}

//Получение чата с пользователем

export async function getChat(idUser) {
  let form = new FormData();
  form.append("idOwner", id);
  form.append("idUser", idUser);

  const response = await fetch(domen + "/showcase/getChat.php", {
    method: "POST",
    body: form,
  });
  return response.json();
}

//Отправка сообщения

export async function sendMessage(text) {
  let form = new FormData();
  form.append("idOwner", id);
  form.append("time", new Date().toString());
  form.append("Bot", 1);
  form.append("text", text);
  form.append("idUser", JSON.parse(localStorage.user).ID);
  const response = await fetch(domen + "/showcase/sendMessage.php", {
    method: "POST",
    body: form,
  });
  return response.status;
}

//Получения информации о USER через телефон

export async function getUserByPhone(phone) {
  let form = new FormData();

  form.append("phone", phone);

  const response = await fetch(domen + "/showcase/getUserByPhone.php", {
    method: "POST",
    body: form,
  });
  return response.json();
}

//Получение списка тем

export async function getThemes(idTheme) {
  let form = new FormData();
  form.append("id", idTheme);
  const response = await fetch(
    "https://comeback-team.ru/showcase/Themes/getThemes.php",
    {
      method: "POST",
      body: form,
    }
  );

  return await response.json();
}

//Обновление темы

export async function updateTheme(idTheme, arr) {
  let form = new FormData();
  form.append("id", idTheme);
  form.append("arr", arr);
  const response = await fetch(
    "https://comeback-team.ru/showcase/Themes/updateTheme.php",
    {
      method: "POST",
      body: form,
    }
  );

  return await response.json();
}
