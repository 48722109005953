import { useEffect, useState } from "react";
import "./style.css";
import { Form, useNavigate } from "react-router-dom";
import { formatDate, getOrders, updateStatus } from "../../Api";
import messageI from "./message.svg";
import { Store } from "react-notifications-component";
import { routes } from "../../routes";
const typeOrders = [
  "Создан",
  "В сборке",
  "В доставке",
  "Ожидает получения",
  "Получен",
  "Готов к самовывозу",
  "Отменен",
];
export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [currentType, setCurrentType] = useState(typeOrders[0]);
  const [openPop, isOpenPop] = useState(false);
  const [message, setMessage] = useState("");
  const Navigate = useNavigate();
  useEffect(() => {
    getOrders().then((data) => {
      setOrders(data);
    });
  }, []);
  return (
    <div className="ordersTab container">
      <div className="typesOrder">
        {typeOrders.map((em, index) => {
          return (
            <div
              style={
                em == currentType
                  ? {
                      border: "2px solid #DEE0EA",
                      borderRadius: "15px 15px 0 0",
                      borderBottom: "2px solid #edf0fd",
                    }
                  : {}
              }
              key={index}
              onClick={() => setCurrentType(em)}
            >
              <p style={em == currentType ? { color: "#3E95FF" } : {}}>{em}</p>
              <span>
                {orders &&
                  orders.length > 0 &&
                  orders.filter(function (value) {
                    return em === value.StatusComp;
                  }).length}
              </span>
            </div>
          );
        })}
        <div className="lastType"></div>
      </div>

      <div className="ordersColumn">
        {orders &&
          orders.length > 0 &&
          orders.map((em, index) => {
            if (em.idUser == "1078021722") {
              // return;
            }

            if (em.StatusComp == currentType) {
              let products = JSON.parse(em.Positions);
              return (
                <div className="orderElem" key={index}>
                  <div className="bord">
                    <p className="titleOr">
                      Заказ № {em.ID} от {formatDate(JSON.parse(em.timeOrder))}
                    </p>
                    <div className="statusOrder">
                      Создан{" "}
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="1"
                          width="15"
                          height="15"
                          rx="7.5"
                          stroke="#00C2FF"
                        />
                        <path d="M5 7L8 10L11 7" stroke="black" />
                      </svg>
                      <div className="changeSt">
                        {typeOrders.map((elem) => {
                          if (elem != currentType)
                            return (
                              <p
                                onClick={() => {
                                  updateStatus(em.ID, elem).then((res) => {
                                    getOrders().then((data) => {
                                      Store.addNotification({
                                        title: `Заказ №${em.ID}`,
                                        message: `Статус заказа изменен на "${elem}"`,
                                        type: "success",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: [
                                          "animate__animated",
                                          "animate__bounceIn",
                                        ],
                                        animationOut: [
                                          "animate__animated",
                                          "animate__backOutUp",
                                        ],
                                        dismiss: {
                                          duration: 4000,
                                          onScreen: true,
                                        },
                                      });
                                      setOrders(data);
                                    });
                                  });
                                }}
                              >
                                {elem}
                              </p>
                            );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="orderBody">
                    <p>{em.telephone}</p>
                    <span>ФИО: Кирилл</span>
                    <span>Позиций в заказе: {products.length}</span>
                    <span>Сумма заказа: {em.summ} ₽</span>
                    <span>
                      Адрес доставки: г.Санкт Петербург, ул. Ломоносова 24,
                      парадная 3, кв. 23
                    </span>
                  </div>
                  <div className="ordersButton">
                    <img
                      src={messageI}
                      onClick={() =>
                        Navigate(routes.chat + `?key=${em.telephone}`)
                      }
                      alt=""
                    />
                    <div
                      className="orderDetails"
                      onClick={() =>
                        Navigate(routes.orderDetails + `?key=${em.ID}`)
                      }
                    >
                      Детали заказа
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
}
