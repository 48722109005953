import "./admin.css";
import adminPhone from "../imgs/adminPhone.png";
import AdminPass from "../imgs/adminPass.png";
import AdminProfileIcon from "../imgs/adminProfileIcon.png";
import comebackteamImg from "../imgs/comebackteamImg.png";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { routes } from "../routes";
export default function AdminLogin() {
  const Navigate = useNavigate();
  const [form, setForm] = useState({
    tel: "+7",
    password: "",
  });
  const [errorText, seterrorText] = useState("");
  const handleChange = (event) => {
    switch (event.target.name) {
      case "tel":
        mask(event);
        setForm({
          ...form,
          tel: event.target.value,
        });
        break;
      case "password":
        setForm({
          ...form,
          password: event.target.value,
        });
        break;
    }
    console.log(form);
  };
  const auth = (event) => {
    console.log("enterLogin");
    event.preventDefault();
    const data = new FormData();
    data.append("tel", form.tel);
    data.append("password", form.password);

    fetch("https://comeback-team.ru/auth.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data != "error") {
          if (data == "admin") {
            Navigate(routes.adminMainTable);
          } else {
            seterrorText("");
            let arr = data.split(",");
            if (arr[0] != "1") {
              alert("Вы находитесь в процессе регистрации");
            } else {
              localStorage.setItem("tel", form.tel);
              localStorage.setItem("id", arr[1]);
              localStorage.setItem("type", arr[2]);
              localStorage.setItem("token", arr[3]);
              localStorage.setItem("password", form.password);
              Navigate(routes.main);
            }
          }
        } else {
          seterrorText("Неверные данные для входа");
        }
        //document.location.href = "htts://webby-digital.ru/thanks.html";
        //else alert(data);
      });
  };
  function mask(event) {
    let keyCode;
    event.keyCode && (keyCode = event.keyCode);
    let pos = event.target.selectionStart;
    if (pos < 3) event.preventDefault();
    let matrix = "+7 (___) ___ ____",
      i = 0,
      def = matrix.replace(/\D/g, ""),
      val = event.target.value.replace(/\D/g, ""),
      new_value = matrix.replace(/[_\d]/g, function (a) {
        return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
      });
    i = new_value.indexOf("_");
    if (i != -1) {
      i < 5 && (i = 3);
      new_value = new_value.slice(0, i);
    }
    let reg = matrix
      .substr(0, event.target.value.length)
      .replace(/_+/g, function (a) {
        return "\\d{1," + a.length + "}";
      })
      .replace(/[+()]/g, "\\$&");
    reg = new RegExp("^" + reg + "$");
    if (
      !reg.test(event.target.value) ||
      event.target.value.length < 5 ||
      (keyCode > 47 && keyCode < 58)
    )
      event.target.value = new_value;
    if (event.type == "blur" && event.target.value.length < 5)
      event.target.value = "";
  }
  return (
    <div className="admin_enter_container">
      <p className="admin_enter_title">
        Вход {document.body.clientWidth < 640 && <br />} в личный кабинет
      </p>
      <div className="right_part_admin">
        <div className="admin_enter_inputs_white_background">
          <div className="admin_enter_inputs_box">
            <div className="admin_author_register_box">
              <p id="admin_choose_author" className="admin_chooser_active">
                Авторизация
              </p>
              <Link
                to="/register"
                id="admin_choose_register"
                className="admin_chooser_disabled"
              >
                Регистрация
              </Link>
            </div>
            <p className="admin_enter_inputs_title">Заполните поля для входа</p>

            <form type="post" onSubmit={auth}>
              <div className="admin_enter_phone_input_box">
                <div className="admin_enter_input_img_box">
                  <img className="admin_enter_input_img" src={adminPhone} />
                </div>
                <div className="password_is_short">{errorText}</div>
                <input
                  type="text"
                  className="admin_enter_inputs"
                  placeholder="Телефон"
                  name="tel"
                  value={form.tel}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="admin_enter_phone_input_box">
                <div className="admin_enter_input_img_box">
                  <img className="AdminPass" src={AdminPass} />
                </div>
                <input
                  type="password"
                  className="admin_enter_inputs"
                  placeholder="Пароль"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                ></input>
              </div>
              <button className="admin_enter_button" onClick={auth}>
                Войти
              </button>
            </form>
          </div>
        </div>
        <div className="comeback_team_box">
          <img className="comebackteamImg" src={comebackteamImg} />
          <p className="comeback_team_title">
            <span>ComeBack</span> <br /> Team
          </p>
        </div>
      </div>
    </div>
  );
}
